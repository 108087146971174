import {
    IProps,
} from './InputNumber.types';

import React, {
    useRef,
} from 'react';
import {
    Input,
} from 'antd';

const INITIAL_VALUE = '';

export default function InputNumber({
    prefix = '',
    initialValue = INITIAL_VALUE,
    onChangeValue,
    ...props
}: IProps) {
    const value = useRef(initialValue);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const pureValue = prefix
            ? event.target.value.split(prefix)[1] || ''
            : event.target.value;
        const numberValue = (pureValue.match(/(\d+)/) || [])[0] || '';
        const nextValue = `${prefix}${numberValue}`;
        value.current = nextValue;
        onChangeValue(nextValue);
    };
    const onBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
        if (typeof props.onBlur === 'function') {
            props.onBlur(evt);
        }

        if (prefix && value.current === prefix) {
            value.current = INITIAL_VALUE;
            onChangeValue(INITIAL_VALUE);
        }
    };
    const onFocus = () => {
        if (prefix && !value.current) {
            value.current = prefix;
            onChangeValue(prefix);
        }
    };

    return (
        <Input
            {...props}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
        />
    );
}
