import {
    AvailableLocales,
} from 'src/libs/locale';

export default {
    [AvailableLocales.Russian]: {
        title: 'Прямиком: Стать курьером',
    },
    [AvailableLocales.English]: {
        title: 'Master Delivery: Become a courier',
    },
    [AvailableLocales.French]: {
        title: 'Master Delivery: Devenir un courrier',
    },
    [AvailableLocales.German]: {
        title: 'Master Delivery: Werden Sie ein Kurier',
    },
    [AvailableLocales.Spanish]: {
        title: 'Master Delivery: Conviértete en un mensajero',
    },
    [AvailableLocales.Italian]: {
        title: 'Master Delivery: Diventa un corriere',
    },
    [AvailableLocales.Turkish]: {
        title: 'Master Delivery: Bir kurye ol',
    },
};
