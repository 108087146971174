import {
    FormError,
    FormValidation,
    ICity,
    ICountry,
} from 'src/dataSources';

export enum Screens {
    PersonalData,
    PhoneConfirmation,
    Citizenship,
    WorkingConditions,
    Success,
}

export interface IFormsValues {
    first_name: string;
    last_name: string;
    phone_number: string;
    city_id: number;
    is_agree: boolean;

    otp_code: string;

    nationality_id: string;
    birth_date: string;
    // virtual fields: year-month-day
    birth_date_day: string;
    birth_date_month: string;
    birth_date_year: string;

    method_delivery: string;
    prefer_working_days: string;
    prefer_working_hours: string;
    token: string;
    user_ip: string;
    visible: boolean;
    captcha_key: number;
}

export interface IProps {
    cities: ICity[];
}

export interface IState {
    countdown: number;
    currentScreen: Screens;
    formsValues: IFormsValues;
    submitting: boolean;
    validation: FormValidation<IFormsValues>;
    errors: FormError[];
    countries: ICountry[];
    showRules: boolean;
}

export enum PreferWorkingDaysOption {
    TwoFour = '0',
    FiveSix = '1',
}

export enum PreferWorkingHoursOption {
    Main = '0',
    Freelance = '1',
}

export enum MethodDeliveryOption {
    OnFoot = '0',
    Bike = '2',
    Car = '4',
}

export type FormValue = string | number | boolean
export type FieldValidationRules = Array<(value: FormValue) => boolean>
export type FormValidationRules = Partial<Record<
    keyof IFormsValues,
    FieldValidationRules
>>
