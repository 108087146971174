import {
    UTM_SOURCE_KEY,
    UTM_SOURCE_WEBVIEW,
} from 'src/config'

function isIOS(): boolean {
    const {
        maxTouchPoints,
        userAgent,
    } = navigator;

    return userAgent.search(/'iPad|iPhone|iPod/) > 0
        || userAgent.includes('Mac') && maxTouchPoints > 1;
}

function isAndroid() {
    return navigator.userAgent.includes('Android')
}

export function isIOSWebView() {
    return isIOS() && /AppleWebKit(?!.*Safari)/i.test(navigator.userAgent)
}

export function isAndroidWebView() {
    return isAndroid() && /wv|Version\//i.test(navigator.userAgent);
}

export function isWebView() {
    const queryParams = new URLSearchParams(window.location.search);
    const utmSource = queryParams.get(UTM_SOURCE_KEY);

    return UTM_SOURCE_WEBVIEW.includes(utmSource)
        || isIOSWebView()
        || isAndroidWebView();
}
