import CustomParams = Gtag.CustomParams

import {
    get as localStorageGet,
} from 'local-storage';

import {
    REFERRAL_KEY,
    AnalyticEvents,
} from 'src/config';

window.dataLayer = window.dataLayer || [];
window.gtag = function() {
    window.dataLayer.push(arguments);
}

gtag('js', new Date());
gtag('config', 'G-JDHS6NT7WQ');

export function sendGoogle(
    event: AnalyticEvents,
    params: CustomParams = {},
) {
    const additionalParams = {...params};

    const referralValue = localStorageGet(REFERRAL_KEY);
    if (referralValue) {
        additionalParams.referral = referralValue;
    }

    window.gtag('event', event, additionalParams);
}
