import {
    AvailableLocales,
} from 'src/libs/locale';
import {
    MethodDeliveryOption,
    PreferWorkingDaysOption,
    PreferWorkingHoursOption,
    Screens,
} from './Form.types';

export default {
    [AvailableLocales.Russian]: {
        header: {
            title: {
                [Screens.PersonalData]: 'Стать курьером',
                [Screens.PhoneConfirmation]: 'Подтвердите номер телефона',
                [Screens.Citizenship]: 'Стать курьером',
                [Screens.WorkingConditions]: 'Стать курьером',
                [Screens.Success]: 'Анкета отправлена!',
            },
            steps: {
                [Screens.PersonalData]: 'Личные данные',
                [Screens.Citizenship]: 'Гражданство',
                [Screens.WorkingConditions]: 'Условия работы',
            },
        },
        screens: {
            [Screens.PersonalData]: {
                form: {
                    first_name: {
                        label: 'Имя',
                    },
                    last_name: {
                        label: 'Фамилия',
                    },
                    middle_name: {
                        label: 'Отчество',
                    },
                    phone_number: {
                        label: 'Телефон',
                    },
                    city_id: {
                        label: 'Город',
                        placeholder: 'Выберите город',
                    },
                    is_agree: {
                        text: 'Я согласен с ',
                        link: 'Правилами обработки персональных данных',
                    },
                },
                buttons: {
                    submit: 'Дальше',
                },
            },
            [Screens.PhoneConfirmation]: {
                title: 'Вам отправлено смс с кодом подтверждения на&nbsp;номер <nobr><strong>{number}</strong>.</nobr>',
                buttons: {
                    goBack: 'Изменить номер телефона',
                    submit: 'Подтвердить',
                },
                retry: 'Отправить код повторно',
                invalidCode: 'Введен неверный код.',
                retryAfter: [
                    'Повторно получить код можно будет через&nbsp;{countdown}&nbsp;секунду',
                    'Повторно получить код можно будет через&nbsp;{countdown}&nbsp;секунды',
                    'Повторно получить код можно будет через&nbsp;{countdown}&nbsp;секунд',
                ],
            },
            [Screens.Citizenship]: {
                form: {
                    nationality_id: {
                        label: 'Гражданство',
                        placeholder: 'Не выбрано',
                    },
                    birth_date: {
                        label: 'Дата рождения',
                        day: {
                            label: 'День',
                        },
                        month: {
                            label: 'Месяц',
                        },
                        year: {
                            label: 'Год',
                        },
                    },
                },
                buttons: {
                    goBack: 'Назад',
                    submit: 'Дальше',
                },
            },
            [Screens.WorkingConditions]: {
                form: {
                    prefer_working_days: {
                        label: 'Сколько дней в неделю вы хотите работать',
                        options: {
                            [PreferWorkingDaysOption.TwoFour]: '2-4 дня',
                            [PreferWorkingDaysOption.FiveSix]: '5-6 дней',
                        },
                    },
                    prefer_working_hours: {
                        label: 'Сколько времени вы хотите работать',
                        options: {
                            [PreferWorkingHoursOption.Main]: 'Ищу основную работу',
                            [PreferWorkingHoursOption.Freelance]: 'Ищу подработку',
                        },
                    },
                    method_delivery: {
                        label: 'Как вы хотите доставлять',
                        options: {
                            [MethodDeliveryOption.OnFoot]: 'Пешком',
                            [MethodDeliveryOption.Car]: 'На автомобиле',
                            [MethodDeliveryOption.Bike]: 'На велосипеде',
                        },
                    },
                },
                buttons: {
                    goBack: 'Назад',
                    submit: 'Дальше',
                },
            },
            [Screens.Success]: {
                subTitle: 'Спасибо, ваша анкета отправлена. В ближайшее время вам позвонит наш менеджер.',
                backLink: 'Вернуться на сайт «Прямиком»',
                backToApp: 'Вернуться в приложение',
            },
        },
    },
    [AvailableLocales.English]: {
        header: {
            title: {
                [Screens.PersonalData]: 'Become a courier',
                [Screens.PhoneConfirmation]: 'Confirm Phone Number',
                [Screens.Citizenship]: 'Become a courier',
                [Screens.WorkingConditions]: 'Become a courier',
                [Screens.Success]: 'The questionnaire has been sent!',
            },
            steps: {
                [Screens.PersonalData]: 'Personal data',
                [Screens.Citizenship]: 'Citizenship',
                [Screens.WorkingConditions]: 'Working Conditions',
            },
        },
        screens: {
            [Screens.PersonalData]: {
                form: {
                    first_name: {
                        label: 'Name',
                    },
                    last_name: {
                        label: 'Last name',
                    },
                    middle_name: {
                        label: 'Middle name',
                    },
                    phone_number: {
                        label: 'Phone',
                    },
                    city_id: {
                        label: 'City',
                        placeholder: 'Select city',
                    },
                    is_agree: {
                        text: 'I agree with ',
                        link: 'Rules for the processing of personal data',
                    },
                },
                buttons: {
                    submit: 'Next',
                },
            },
            [Screens.PhoneConfirmation]: {
                title: 'An SMS has been sent to you with a confirmation code to&nbsp;number <nobr><strong>{number}</strong>.</nobr>',
                buttons: {
                    goBack: 'Change phone number',
                    submit: 'Next',
                },
                retry: 'Send code again',
                invalidCode: 'Invalid code entered.',
                retryAfter: [
                    'You can get the code again in&nbsp;{countdown}&nbsp;second',
                    'You can get the code again in&nbsp;{countdown}&nbsp;seconds',
                ],
            },
            [Screens.Citizenship]: {
                form: {
                    nationality_id: {
                        label: 'Citizenship',
                        placeholder: 'Not selected',
                    },
                    birth_date: {
                        label: 'Date of birth',
                        day: {
                            label: 'Day',
                        },
                        month: {
                            label: 'Month',
                        },
                        year: {
                            label: 'Year',
                        },
                    },
                },
                buttons: {
                    goBack: 'Back',
                    submit: 'Next',
                },
            },
            [Screens.WorkingConditions]: {
                form: {
                    prefer_working_days: {
                        label: 'How many days per week do you want to work',
                        options: {
                            [PreferWorkingDaysOption.TwoFour]: '2-4 days',
                            [PreferWorkingDaysOption.FiveSix]: '5-6 days',
                        },
                    },
                    prefer_working_hours: {
                        label: 'How long do you want to work',
                        options: {
                            [PreferWorkingHoursOption.Main]: 'Looking for a main job',
                            [PreferWorkingHoursOption.Freelance]: 'Looking for a part-time job',
                        },
                    },
                    method_delivery: {
                        label: 'How do you want to deliver',
                        options: {
                            [MethodDeliveryOption.OnFoot]: 'On foot',
                            [MethodDeliveryOption.Car]: 'By car',
                            [MethodDeliveryOption.Bike]: 'By bike',
                        },
                    },
                },
                buttons: {
                    goBack: 'Back',
                    submit: 'Next',
                },
            },
            [Screens.Success]: {
                subTitle: 'Thank you, your profile has been submitted. Our manager will call you shortly.',
                backLink: 'Return to the site «Master Delivery»',
            },
        },
    },
    [AvailableLocales.French]: {
        header: {
            title: {
                [Screens.PersonalData]: 'Devenir coursier',
                [Screens.PhoneConfirmation]: 'Confirmer le numéro de téléphone',
                [Screens.Citizenship]: 'Devenir coursier',
                [Screens.WorkingConditions]: 'Devenir coursier',
                [Screens.Success]: 'Le questionnaire a été envoyé!',
            },
            steps: {
                [Screens.PersonalData]: 'Données personnelles',
                [Screens.Citizenship]: 'Citoyenneté',
                [Screens.WorkingConditions]: 'Conditions de travail',
            },
        },
        screens: {
            [Screens.PersonalData]: {
                form: {
                    first_name: {
                        label: 'Nom',
                    },
                    last_name: {
                        label: 'Nom de famille',
                    },
                    middle_name: {
                        label: 'Deuxième nom',
                    },
                    phone_number: {
                        label: 'Téléphone',
                    },
                    city_id: {
                        label: 'Ville',
                        placeholder: 'Sélectionnez la ville',
                    },
                    is_agree: {
                        text: 'Je suis d\'accord avec',
                        link: 'Règles pour le traitement des données personnelles',
                    },
                },
                buttons: {
                    submit: 'Suivant',
                },
            },
            [Screens.PhoneConfirmation]: {
                title: 'Un SMS vous a été envoyé avec un code de confirmation au&nbsp;numéro <nobr><strong>{number}</strong>.</nobr>',
                buttons: {
                    goBack: 'Modifier le numéro de téléphone',
                    submit: 'Suivant',
                },
                retry: 'Envoyer à nouveau le code',
                invalidCode: 'Code non valide saisi.',
                retryAfter: [
                    'Vous pouvez récupérer le code en&nbsp;{countdown}&nbsp;seconde',
                    'Vous pouvez récupérer le code en&nbsp;{countdown}&nbsp;secondes',
                ],
            },
            [Screens.Citizenship]: {
                form: {
                    nationality_id: {
                        label: 'Citoyenneté',
                        placeholder: 'Non sélectionné',
                    },
                    birth_date: {
                        label: 'Date de naissance',
                        day: {
                            label: 'Jour',
                        },
                        month: {
                            label: 'Mois',
                        },
                        year: {
                            label: 'Année',
                        },
                    },
                },
                buttons: {
                    goBack: 'Retour',
                    submit: 'Suivant',
                },
            },
            [Screens.WorkingConditions]: {
                form: {
                    prefer_working_days: {
                        label: 'Combien de jours par semaine voulez-vous travailler',
                        options: {
                            [PreferWorkingDaysOption.TwoFour]: '2-4 jours',
                            [PreferWorkingDaysOption.FiveSix]: '5-6 jours',
                        },
                    },
                    prefer_working_hours: {
                        label: 'Combien de temps voulez-vous travailler',
                        options: {
                            [PreferWorkingHoursOption.Main]: 'A la recherche d\'un emploi principal',
                            [PreferWorkingHoursOption.Freelance]: 'A la recherche d\'un emploi à temps partiel',
                        },
                    },
                    method_delivery: {
                        label: 'Comment voulez-vous livrer',
                        options: {
                            [MethodDeliveryOption.OnFoot]: 'A pied',
                            [MethodDeliveryOption.Car]: 'En voiture',
                            [MethodDeliveryOption.Bike]: 'A vélo',
                        },
                    },
                },
                buttons: {
                    goBack: 'Retour',
                    submit: 'Suivant',
                },
            },
            [Screens.Success]: {
                subTitle: 'Merci, votre profil a été soumis. Notre responsable vous appellera sous peu.',
                backLink: 'Retour au site «Master Delivery»',
            },
        },
    },
    [AvailableLocales.German]: {
        header: {
            title: {
                [Screens.PersonalData]: 'Kurier werden',
                [Screens.PhoneConfirmation]: 'Telefonnummer bestätigen',
                [Screens.Citizenship]: 'Kurier werden',
                [Screens.WorkingConditions]: 'Kurier werden',
                [Screens.Success]: 'Der Fragebogen wurde versendet!',
            },
            steps: {
                [Screens.PersonalData]: 'Persönliche Daten',
                [Screens.Citizenship]: 'Staatsbürgerschaft',
                [Screens.WorkingConditions]: 'Arbeitsbedingungen',
            },
        },
        screens: {
            [Screens.PersonalData]: {
                form: {
                    first_name: {
                        label: 'Name',
                    },
                    last_name: {
                        label: 'Nachname',
                    },
                    middle_name: {
                        label: 'Zweiter Vorname',
                    },
                    phone_number: {
                        label: 'Telefon',
                    },
                    city_id: {
                        label: 'Stadt',
                        placeholder: 'Stadt auswählen',
                    },
                    is_agree: {
                        text: 'Ich stimme zu ',
                        link: 'Regeln für die Verarbeitung personenbezogener Daten',
                    },
                },
                buttons: {
                    submit: 'Weiter',
                },
            },
            [Screens.PhoneConfirmation]: {
                title: 'Eine SMS wurde mit einem Bestätigungscode an die&nbsp;Nummer <nobr><strong>{number}</strong></nobr> gesendet.',
                buttons: {
                    goBack: 'Telefonnummer ändern',
                    submit: 'Weiter',
                },
                retry: 'Code erneut senden',
                invalidCode: 'Ungültiger Code eingegeben.',
                retryAfter: [
                    'Sie können den Code in&nbsp;{countdown}&nbsp;Sekunde erneut abrufen',
                    'Sie können den Code in&nbsp;{countdown}&nbsp;Sekunden erneut abrufen',
                ],
            },
            [Screens.Citizenship]: {
                form: {
                    nationality_id: {
                        label: 'Staatsbürgerschaft',
                        placeholder: 'Nicht ausgewählt',
                    },
                    birth_date: {
                        label: 'Geburtsdatum',
                        day: {
                            label: 'Tag',
                        },
                        month: {
                            label: 'Monat',
                        },
                        year: {
                            label: 'Jahr',
                        },
                    },
                },
                buttons: {
                    goBack: 'Zurück',
                    submit: 'Weiter',
                },
            },
            [Screens.WorkingConditions]: {
                form: {
                    prefer_working_days: {
                        label: 'Wie viele Tage pro Woche möchten Sie arbeiten',
                        options: {
                            [PreferWorkingDaysOption.TwoFour]: '2-4 Tage',
                            [PreferWorkingDaysOption.FiveSix]: '5-6 Tage',
                        },
                    },
                    prefer_working_hours: {
                        label: 'Wie lange möchten Sie arbeiten',
                        options: {
                            [PreferWorkingHoursOption.Main]: 'Auf der Suche nach einem Hauptjob',
                            [PreferWorkingHoursOption.Freelance]: 'Auf der Suche nach einem Teilzeitjob',
                        },
                    },
                    method_delivery: {
                        label: 'Wie möchten Sie liefern',
                        options: {
                            [MethodDeliveryOption.OnFoot]: 'Zu Fuß',
                            [MethodDeliveryOption.Car]: 'Mit dem Auto',
                            [MethodDeliveryOption.Bike]: 'Mit dem Fahrrad',
                        },
                    },
                },
                buttons: {
                    goBack: 'Zurück',
                    submit: 'Weiter',
                },
            },
            [Screens.Success]: {
                subTitle: 'Danke, Ihr Profil wurde übermittelt. Unser Manager wird Sie in Kürze anrufen.',
                backLink: 'Zurück zur Seite «Master Delivery»',
            },
        },
    },
    [AvailableLocales.Spanish]: {
        header: {
            title: {
                [Screens.PersonalData]: 'Conviértase en un mensajero',
                [Screens.PhoneConfirmation]: 'Confirmar número de teléfono',
                [Screens.Citizenship]: 'Conviértase en mensajero',
                [Screens.WorkingConditions]: 'Conviértase en mensajero',
                [Screens.Success]: '¡El cuestionario ha sido enviado!',
            },
            steps: {
                [Screens.PersonalData]: 'Datos personales',
                [Screens.Citizenship]: 'Ciudadanía',
                [Screens.WorkingConditions]: 'Condiciones de trabajo',
            },
        },
        screens: {
            [Screens.PersonalData]: {
                form: {
                    first_name: {
                        label: 'Nombre',
                    },
                    last_name: {
                        label: 'Apellido',
                    },
                    middle_name: {
                        label: 'Segundo nombre',
                    },
                    phone_number: {
                        label: 'Teléfono',
                    },
                    city_id: {
                        label: 'Ciudad',
                        placeholder: 'Seleccionar ciudad',
                    },
                    is_agree: {
                        text: 'Estoy de acuerdo con ',
                        link: 'Reglas para el procesamiento de datos personales',
                    },
                },
                buttons: {
                    submit: 'Siguiente',
                },
            },
            [Screens.PhoneConfirmation]: {
                title: 'Se envió un SMS con un código de verificación al&nbsp;número <nobr><strong>{number}</strong>.</nobr>',
                buttons: {
                    goBack: 'Cambiar número de teléfono',
                    submit: 'Siguiente',
                },
                retry: 'Enviar código de nuevo',
                invalidCode: 'Se ingresó un código no válido.',
                retryAfter: [
                    'Puede obtener el código nuevamente en&nbsp;{countdown}&nbsp;segundo',
                    'Puede obtener el código nuevamente en&nbsp;{countdown}&nbsp;segundos',
                ],
            },
            [Screens.Citizenship]: {
                form: {
                    nationality_id: {
                        label: 'Ciudadanía',
                        placeholder: 'No seleccionado',
                    },
                    birth_date: {
                        label: 'Fecha de nacimiento',
                        day: {
                            label: 'Día',
                        },
                        month: {
                            label: 'Mes',
                        },
                        year: {
                            label: 'Año',
                        },
                    },
                },
                buttons: {
                    goBack: 'Atrás',
                    submit: 'Siguiente',
                },
            },
            [Screens.WorkingConditions]: {
                form: {
                    prefer_working_days: {
                        label: '¿Cuántos días a la semana quieres trabajar?',
                        options: {
                            [PreferWorkingDaysOption.TwoFour]: '2-4 días',
                            [PreferWorkingDaysOption.FiveSix]: '5-6 días',
                        },
                    },
                    prefer_working_hours: {
                        label: '¿Cuánto tiempo quieres trabajar?',
                        options: {
                            [PreferWorkingHoursOption.Main]: 'Buscando un trabajo principal',
                            [PreferWorkingHoursOption.Freelance]: 'Buscando un trabajo a tiempo parcial',
                        },
                    },
                    method_delivery: {
                        label: 'Cómo desea realizar la entrega',
                        options: {
                            [MethodDeliveryOption.OnFoot]: 'A pie',
                            [MethodDeliveryOption.Car]: 'En coche',
                            [MethodDeliveryOption.Bike]: 'En bicicleta',
                        },
                    },
                },
                buttons: {
                    goBack: 'Atrás',
                    submit: 'Siguiente',
                },
            },
            [Screens.Success]: {
                subTitle: 'Gracias, su perfil ha sido enviado. Nuestro gerente le llamará en breve.',
                backLink: 'Regresar al sitio «Master Delivery»',
            },
        },
    },
    [AvailableLocales.Italian]: {
        header: {
            title: {
                [Screens.PersonalData]: 'Diventa corriere',
                [Screens.PhoneConfirmation]: 'Conferma numero di telefono',
                [Screens.Citizenship]: 'Diventa corriere',
                [Screens.WorkingConditions]: 'Diventa corriere',
                [Screens.Success]: 'Il questionario è stato inviato!',
            },
            steps: {
                [Screens.PersonalData]: 'Dati personali',
                [Screens.Citizenship]: 'Cittadinanza',
                [Screens.WorkingConditions]: 'Condizioni di lavoro',
            },
        },
        screens: {
            [Screens.PersonalData]: {
                form: {
                    first_name: {
                        label: 'Nome',
                    },
                    last_name: {
                        label: 'Cognome',
                    },
                    middle_name: {
                        label: 'Secondo nome',
                    },
                    phone_number: {
                        label: 'Telefono',
                    },
                    city_id: {
                        label: 'Città',
                        placeholder: 'Seleziona città',
                    },
                    is_agree: {
                        text: 'Sono d\'accordo con ',
                        link: 'Norme per il trattamento dei dati personali',
                    },
                },
                buttons: {
                    submit: 'Avanti',
                },
            },
            [Screens.PhoneConfirmation]: {
                title: 'È stato inviato un SMS con un codice di verifica al&nbsp;numero <nobr><strong>{number}</strong>.</nobr>',
                buttons: {
                    goBack: 'Cambia numero di telefono',
                    submit: 'Avanti',
                },
                retry: 'Invia nuovamente il codice',
                invalidCode: 'Codice inserito non valido.',
                retryAfter: [
                    'Puoi ottenere di nuovo il codice in&nbsp;{countdown}&nbsp;secondo',
                    'Puoi ottenere di nuovo il codice in&nbsp;{countdown}&nbsp;secondi',
                ],
            },
            [Screens.Citizenship]: {
                form: {
                    nationality_id: {
                        label: 'Cittadinanza',
                        placeholder: 'Non selezionato',
                    },
                    birth_date: {
                        label: 'Data di nascita',
                        day: {
                            label: 'Giorno',
                        },
                        month: {
                            label: 'Mese',
                        },
                        year: {
                            label: 'Anno',
                        },
                    },
                },
                buttons: {
                    goBack: 'Indietro',
                    submit: 'Avanti',
                },
            },
            [Screens.WorkingConditions]: {
                form: {
                    prefer_working_days: {
                        label: 'Quanti giorni alla settimana vuoi lavorare',
                        options: {
                            [PreferWorkingDaysOption.TwoFour]: '2-4 giorni',
                            [PreferWorkingDaysOption.FiveSix]: '5-6 giorni',
                        },
                    },
                    prefer_working_hours: {
                        label: 'Per quanto tempo vuoi lavorare',
                        options: {
                            [PreferWorkingHoursOption.Main]: 'Cerca lavoro principale',
                            [PreferWorkingHoursOption.Freelance]: 'Cerco lavoro part-time',
                        },
                    },
                    method_delivery: {
                        label: 'Come vuoi consegnare',
                        options: {
                            [MethodDeliveryOption.OnFoot]: 'A piedi',
                            [MethodDeliveryOption.Car]: 'In auto',
                            [MethodDeliveryOption.Bike]: 'In bicicletta',
                        },
                    },
                },
                buttons: {
                    goBack: 'Indietro',
                    submit: 'Avanti',
                },
            },
            [Screens.Success]: {
                subTitle: 'Grazie, il tuo profilo è stato inviato. Il nostro manager ti chiamerà a breve.',
                backLink: 'Torna al sito «Master Delivery»',
            },
        },
    },
    [AvailableLocales.Turkish]: {
        header: {
            title: {
                [Screens.PersonalData]: 'Kurye ol',
                [Screens.PhoneConfirmation]: 'Telefon Numarasını Onayla',
                [Screens.Citizenship]: 'Kurye ol',
                [Screens.WorkingConditions]: 'Kurye ol',
                [Screens.Success]: 'Anket gönderildi!',
            },
            steps: {
                [Screens.PersonalData]: 'Kişisel veriler',
                [Screens.Citizenship]: 'Vatandaşlık',
                [Screens.WorkingConditions]: 'Çalışma Koşulları',
            },
        },
        screens: {
            [Screens.PersonalData]: {
                form: {
                    first_name: {
                        label: 'Ad',
                    },
                    last_name: {
                        label: 'Soyadı',
                    },
                    middle_name: {
                        label: 'Göbek adı',
                    },
                    phone_number: {
                        label: 'Telefon',
                    },
                    city_id: {
                        label: 'Şehir',
                        placeholder: 'Şehir seçin',
                    },
                    is_agree: {
                        text: 'Katılıyorum ',
                        link: 'Kişisel verilerin işlenmesine ilişkin kurallar',
                    },
                },
                buttons: {
                    submit: 'Sonraki',
                },
            },
            [Screens.PhoneConfirmation]: {
                title: '<nobr><strong>{number}</strong></nobr> numarasına doğrulama kodu içeren bir SMS gönderildi.',
                buttons: {
                    goBack: 'Telefon numarasını değiştir',
                    submit: 'Sonraki',
                },
                retry: 'Kodu tekrar gönder',
                invalidCode: 'Geçersiz kod girildi.',
                retryAfter: [
                    'Kodu&nbsp;{countdown}&nbsp;saniye içinde tekrar alabilirsiniz',
                    'Kodu&nbsp;{countdown}&nbsp;saniye içinde tekrar alabilirsiniz',
                ],
            },
            [Screens.Citizenship]: {
                form: {
                    nationality_id: {
                        label: 'Vatandaşlık',
                        placeholder: 'Seçilmedi',
                    },
                    birth_date: {
                        label: 'Doğum tarihi',
                        day: {
                            label: 'Gün',
                        },
                        month: {
                            label: 'Ay',
                        },
                        year: {
                            label: 'Yıl',
                        },
                    },
                },
                buttons: {
                    goBack: 'Geri',
                    submit: 'Sonraki',
                },
            },
            [Screens.WorkingConditions]: {
                form: {
                    prefer_working_days: {
                        label: 'Haftada kaç gün çalışmak istiyorsunuz',
                        options: {
                            [PreferWorkingDaysOption.TwoFour]: '2-4 gün',
                            [PreferWorkingDaysOption.FiveSix]: '5-6 gün',
                        },
                    },
                    prefer_working_hours: {
                        label: 'Ne kadar süre çalışmak istiyorsunuz',
                        options: {
                            [PreferWorkingHoursOption.Main]: 'Ana iş arıyorum',
                            [PreferWorkingHoursOption.Freelance]: 'Yarı zamanlı iş arıyorum',
                        },
                    },
                    method_delivery: {
                        label: 'Nasıl teslim etmek istiyorsunuz',
                        options: {
                            [MethodDeliveryOption.OnFoot]: 'Yaya',
                            [MethodDeliveryOption.Car]: 'Arabayla',
                            [MethodDeliveryOption.Bike]: 'Bisikletle',
                        },
                    },
                },
                buttons: {
                    goBack: 'Geri',
                    submit: 'Sonraki',
                },
            },
            [Screens.Success]: {
                subTitle: 'Teşekkürler, profiliniz gönderildi. Yöneticimiz kısa süre içinde sizi arayacak.',
                backLink: '«Master Delivery» sitesine geri dön',
            },
        },
    },
};
