export enum AvailableLocales {
    French = 'fr',
    English = 'en',
    Russian = 'ru',
    Italian = 'it',
    German = 'de',
    Spanish = 'es',
    Turkish = 'tr',
}

function getLanguage(locale: AvailableLocales | string) {
    return locale
        .substr(0, 2)
        .toLowerCase();
}

export function detectLocale(): AvailableLocales {
    // ?lang=ru, ?lang=en
    const pathLocale = (new URLSearchParams(window.location.search)).get('lang');
    // "en", "fr", "es"
    const systemLanguage = getLanguage(navigator.language);
    const resultLocale = pathLocale || systemLanguage;

    const localeMatches = Object.values(AvailableLocales)
        .find((locale) => locale === resultLocale);

    return localeMatches;
}
