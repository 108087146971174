import locales from './dataSources.i18n';

import * as Sentry from '@sentry/react';
import {
    get as localStorageGet,
} from 'local-storage'
import * as _ from 'lodash'; // webview in Android have no window.localStorage

import i18n from 'src/libs/i18n';
export const __ = i18n(locales);

import {
    BACKEND_URL,
    LOCALE,
    REFERRAL_KEY,
} from 'src/config';
import {
    padDate
} from 'src/libs/birthDate'

export interface ICity {
    id: number;
    location: {
        latitude: number;
        longitude: number;
    };
    name: string;
    region_id: number;
    subject: string;
    timezone: string;
    is_duplicate?: boolean;
}

export interface ICountry {
    id: number;
    title: string;
}

interface ICountriesResponse {
    count: number;
    items: ICountry[];
    total: number;
}

export interface ICreateApplicationFormData {
    first_name: string;
    last_name: string;
    phone_number: string;
    city_id: number;
    is_agree: boolean;
    user_ip: string;
    yandex_captcha_token: string;
}

// tslint:disable-next-line:no-empty-interface
export interface ICreateApplicationResponse {}

export interface IVerifyPhoneFormData {
    phone: string;
    otp_code: string;
}

type AccessToken = string;
type RefreshToken = string;

interface IVerifyPhoneResponseToken<Token, Sub> {
    exp: string;
    sub: Sub;
    token: Token;
}

export interface ITokensResponse {
    access_token: IVerifyPhoneResponseToken<AccessToken, 'access'>;
    refresh_token: IVerifyPhoneResponseToken<RefreshToken, 'refresh'>;
    user: {
        city_ids: string[] | null;
        created_at: string;
        email: string;
        groups: string[];
        id: string;
        is_active: boolean;
        phone: string;
        primary_language: string;
        role: string;
    };
}

export interface IUpdatePassportFormData {
    is_agree: boolean;
    nationality_id: string;
    birth_date?: string;
    birth_date_day?: string;
    birth_date_month?: string;
    birth_date_year?: string;
}

// tslint:disable-next-line:no-empty-interface
export interface IUpdatePassportResponse {}

export interface IUpdateApplicationFormData {
    method_delivery?: string;
    prefer_working_days?: string;
    prefer_working_hours?: string;
}

// tslint:disable-next-line:no-empty-interface
export interface IUpdateApplicationResponse {}

interface IHandlerFieldsErrorResponse {
    loc: string[];
    msg: string;
    type: string;
}

export type FormValidation<Obj = any> = Record<keyof Obj, string>;
export type FormError = string;

export interface IFormResponse<Data> {
    ok: boolean;
    data?: Data;
    validation?: FormValidation;
    errors?: FormError[];
}

enum URLPaths {
    Cities = '/merchant/cities/',
    Countries = '/couriers/countries/',
    Applications = '/couriers/onboarding/applications/',
    OTPCheck = '/users/otp/check/',
    Passport = '/couriers/passport/',
    Profile = '/couriers/profile/',
    RefreshToken = '/users/refresh/',
}

let ACCESS_TOKEN: AccessToken = null;
// TODO: lib localstorage
function setAccessToken(token: AccessToken): void {
    ACCESS_TOKEN = token;
}

function getAccessToken(): AccessToken {
    return ACCESS_TOKEN;
}

let refreshTimerId: number = null;
const REFRESH_TOKEN_INTERVAL = 30000;
/**
 * 2021-06-22T15:48:01.040160
 */
function onReceiveTokens(response: ITokensResponse) {
    const accessTokenExpiredTime = response.access_token.exp;
    const diff = accessTokenExpiredTime && (new Date(accessTokenExpiredTime).getTime() - new Date().getTime());

    if (typeof diff === 'number') {
        const timeoutValue = diff > 0 ? (diff - REFRESH_TOKEN_INTERVAL) : 240000;
        if (refreshTimerId) {
            clearTimeout(refreshTimerId);
        }
        refreshTimerId = window.setTimeout(() => refreshToken(response.refresh_token.token), timeoutValue);
    }

    setAccessToken(response.access_token.token);
}

async function refreshToken(token: RefreshToken) {
    const response = await baseFetch<ITokensResponse>(URLPaths.RefreshToken, {
        method: 'POST',
        body: JSON.stringify({
            refresh_token: token,
        }) as any,
    });

    onReceiveTokens(response);
}

export async function getCities(): Promise<ICity[]> {
    try {
        const cities = await baseFetch<ICity[]>(URLPaths.Cities, {
            method: 'GET',
        });

        return cities
            .filter(city => !city.is_duplicate);
    } catch (ex) {
        return [];
    }
}

function normalizePhone(phone: string): string {
    return phone.replace(/[^0-9\+]+/g, '');
}

export async function getCountries(): Promise<ICountry[]> {
    try {
        const response = await baseFetch<ICountriesResponse>(URLPaths.Countries, {
            method: 'GET',
        });

        return response.items;
    } catch (ex) {
        return [];
    }
}

export function createApplication(formData: ICreateApplicationFormData): Promise<IFormResponse<ICreateApplicationResponse>> {
    const body: Record<string, any> = {
        ...formData,
        phone_number: normalizePhone(formData.phone_number),
    };

    const referralValue = localStorageGet(REFERRAL_KEY);
    if (referralValue) {
        body.referral_courier_id = referralValue;
    }

    const responseObj = baseFetch(URLPaths.Applications, {
        method: 'POST',
        body: JSON.stringify(body) as any
    });

    return processForm(responseObj);
}

export async function verifyPhone(formData: IVerifyPhoneFormData): Promise<IFormResponse<ITokensResponse>> {
    const responseObj = baseFetch(URLPaths.OTPCheck, {
        method: 'POST',
        body: JSON.stringify({
            ...formData,
            phone: normalizePhone(formData.phone),
        }) as any,
    });

    const response = await processForm(responseObj);

    if (response.ok) {
        onReceiveTokens(response.data);
    }

    return response;
}

function isAdult(dateString: string, age = 18) {
    const date = new Date(dateString);
    const today = new Date();
    const startDate = new Date(today.getFullYear() - age, today.getMonth(), today.getDate());

    return startDate.getTime() > date.getTime();
}

export async function updatePassport(formData: IUpdatePassportFormData): Promise<IFormResponse<IUpdatePassportResponse>> {
    const body = {
        ...formData,
        birth_date: [
            formData.birth_date_year,
            padDate(formData.birth_date_month),
            padDate(formData.birth_date_day),
        ].join('-'),
    };
    delete body.birth_date_year;
    delete body.birth_date_month;
    delete body.birth_date_day;

    if (!isAdult(body.birth_date)) {
        return processForm(Promise.reject({
            errors: [
                __('reachAgeError'),
            ],
        }));
    }

    const responseObj = baseFetch(URLPaths.Passport, {
        method: 'PATCH',
        body: JSON.stringify(body) as any,
    });

    return processForm(responseObj);
}

function pickFields(formData: object): object {
    return Object.entries(formData).reduce((acc, [key, value]) => {
        if (value === '') {
            return acc;
        }

        return {
            ...acc,
            [key]: value,
        };
    }, {});
}

export async function updateApplication(formData: IUpdateApplicationFormData): Promise<IFormResponse<IUpdateApplicationResponse>> {
    const applicationFormData = pickFields({
        prefer_working_days: formData.prefer_working_days,
        prefer_working_hours: formData.prefer_working_hours,
    });
    const profileFormData = pickFields({
        method_delivery: formData.method_delivery,
    });
    const emptyResponse = Promise.resolve({
        ok: true,
        data: {},
    });

    const responses = await Promise.all([
        Object.keys(applicationFormData).length > 0
            ? baseFetch(URLPaths.Applications, {
                method: 'PATCH',
                body: JSON.stringify(applicationFormData) as any,
            })
            : emptyResponse,
        Object.keys(profileFormData).length > 0
            ? baseFetch(URLPaths.Profile, {
                method: 'PATCH',
                body: JSON.stringify(profileFormData) as any,
            })
            : emptyResponse,
    ].map(response => processForm(response)));

    return {
        ok: responses.every(response => response.ok),
        data: responses.reduce((acc, {data}) => ({
            ...acc,
            ...data,
        }), {}),
        validation: responses.reduce((acc, {validation}) => ({
            ...acc,
            ...validation,
        }), {}),
    };
}

async function processForm<Res = any>(response: Promise<Res>): Promise<IFormResponse<Res>> {
    try {
        const data = await response;

        return {
            ok: true,
            data,
        };
    } catch (ex) {
        return {
            ok: false,
            validation: parseResponseFieldsValidationErrors(ex.errors),
            errors: ex.errors.length && typeof ex.errors[0] === 'string'
                ? ex.errors
                : [],
        };
    }
}

function parseResponseFieldsValidationErrors(errors: IHandlerFieldsErrorResponse[]): FormValidation {
    return errors
        .filter(error => (error.type || '').startsWith('value_error'))
        .filter(error => Array.isArray(error.loc))
        .reduce((acc, error) => ({
            ...acc,
            [error.loc[1]]: error.msg,
        }), {});
}

async function baseFetch<Res = any>(url: string, options: Partial<Request> = {}): Promise<Res> {
    const accessToken = getAccessToken();
    const responseObj = await fetch(`${BACKEND_URL}${url}`, {
        ...options,
        mode: 'cors',
        redirect: 'follow',
        headers: new Headers(_.omitBy({
            'Content-Type': 'application/json',
            'Accept-Language': LOCALE,
            'Authorization': accessToken && `JWT ${accessToken}`,
        }, _.isNil)),
    });

    let response: Res;
    try {
        response = await responseObj.json();
    } catch (ex) {
        Sentry.captureException(ex);

        // HTTP 201
        response = {} as Res;
    }

    return responseObj.ok
        ? response
        : Promise.reject(response);
}
