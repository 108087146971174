import {
    AvailableLocales,
} from 'src/libs/locale';

export default {
    [AvailableLocales.Russian]: {
        reachAgeError: 'Отправить анкету можно только после достижения 18 лет',
    },
    [AvailableLocales.English]: {
        reachAgeError: 'You can send the questionnaire only after reaching the age of 18',
    },
    [AvailableLocales.French]: {
        reachAgeError: 'Vous ne pouvez envoyer le questionnaire qu\'après avoir atteint l\'âge de 18 ans',
    },
    [AvailableLocales.German]: {
        reachAgeError: 'Sie können den Fragebogen erst ab dem 18. Lebensjahr versenden',
    },
    [AvailableLocales.Spanish]: {
        reachAgeError: 'Puede enviar el cuestionario solo después de cumplir los 18 años',
    },
    [AvailableLocales.Italian]: {
        reachAgeError: 'Puoi inviare il questionario solo dopo aver compiuto 18 anni',
    },
    [AvailableLocales.Turkish]: {
        reachAgeError: 'Anketi ancak 18 yaşına ulaştıktan sonra gönderebilirsiniz.',
    },
};
