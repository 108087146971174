import './index.scss';
import locales from './index.i18n';

import * as Sentry from '@sentry/react';
import {
    Integrations,
} from '@sentry/tracing';
import {
    Result,
    Spin,
} from 'antd';
import React from 'react';
import {
    render,
} from 'react-dom';
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from 'react-router-dom';

import i18n from 'src/libs/i18n';
import {
    PRIVACY_POLICY_ROUTE,
    SENTRY_DSN,
} from 'src/config';

import MainLayout from 'src/layouts/MainLayout/MainLayout';
import FormController from 'src/controllers/Form/Form';
import PrivacyPolicy from 'src/components/PrivacyPolicy/PrivacyPolicy';

import {
    getCities,
} from 'src/dataSources';

const __ = i18n(locales);

const {
    NODE_ENV,
} = process.env;

document.addEventListener('DOMContentLoaded', async () => {
    if (NODE_ENV !== 'development') {
        Sentry.init({
            dsn: SENTRY_DSN,
            integrations: [
                new Integrations.BrowserTracing(),
            ],
            tracesSampleRate: 1.0,
        });
    }

    document.title = __('title');
    const mount = document.getElementById('mount');

    render((
        <MainLayout>
            <Result
                icon={(<Spin />)}
            />
        </MainLayout>
    ), mount);

    const cities = await getCities();

    render((
        <Router>
            <MainLayout>
                <Switch>
                    <Route exact path={'/'}>
                        <FormController
                            cities={cities}
                        />
                    </Route>
                    <Route path={PRIVACY_POLICY_ROUTE}>
                        <PrivacyPolicy/>
                    </Route>
                </Switch>
            </MainLayout>
        </Router>
    ), mount);
});
