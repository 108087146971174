import {
    AvailableLocales,
} from 'src/libs/locale';
import * as _ from 'lodash';
import {
    LOCALE,
} from 'src/config';

type Variants = [string, string, string];
type Data = Partial<Record<AvailableLocales, any>>;

export const declension: Partial<Record<AvailableLocales | 'default', (count: number, variants: Variants) => Variants[0]>> = {

    [AvailableLocales.Russian]: (count: number, variants: Variants) => {
        let number = Math.abs(count); // tslint:disable-line variable-name
        number = Math.abs(number);
        number %= 100;

        if (number >= 5 && number <= 20) {
            return variants[2];
        }

        number %= 10;

        if (number === 1) {
            return variants[0];
        }

        if (number >= 2 && number <= 4) {
            return variants[1];
        }

        return variants[2];
    },

    default: (count: number, variants: Variants) => {
        const i = Math.floor(Math.abs(count));
        const v = count.toString().replace(/^[^.]*\.?/, '').length;

        if (i === 1 && v === 0) {
            return variants[0];
        }

        return variants[1];
    },

};

export type I18nFunction = (key: string, replacer?: any, multiplier?: number) => string;

export default function createI18n(data: Data = ({} as Data), path: string = '', locale: AvailableLocales = LOCALE): I18nFunction {
    return (key: string, replacer: any = {}, multiplier: number = 1) => {
        const getKey = [
            locale,
            path,
            key,
        ]
            .filter(Boolean)
            .join('.');
        const variants: Variants = _.get(data, getKey);
        let value: string = typeof variants === 'string' ? variants : undefined;

        replacer = replacer || {};

        if (typeof multiplier === 'number' && Array.isArray(variants)) {
            const declensionByLocale = locale in declension
                ? declension[locale]
                : declension.default;
            value = declensionByLocale(multiplier, variants);
        }

        if (typeof value === 'undefined') {
            return null;
        }

        // TODO use formatMessage
        if (value && Object.keys(replacer).length) {
            value = value.replace(/{([^{}]*)}/ig, (match, p1) => _.get(replacer, _.trim(p1, '{}'), ''));
        }

        return value;
    };
}
