import {
    IFormsValues,
    FormValue,
    FormValidationRules,
} from './Form.types'

import {
    padDate,
} from 'src/libs/birthDate'

export function isDateCorrect(formValues: IFormsValues) {
    const {
        birth_date_day,
        birth_date_month,
        birth_date_year,
    } = formValues;

    return `${padDate(birth_date_day)}${padDate(birth_date_month)}${birth_date_year}`.length === 8;
}

export const validationRules: FormValidationRules = {
    first_name: [
        (firstName: string) => firstName.trim().length >= 2,
    ],
    last_name: [
        (lastName: string) => lastName.trim().length >= 2,
    ],
    phone_number: [
        (number: string) => number.length >= 11,
    ],
    city_id: [
        Boolean,
    ],
    is_agree: [
        Boolean,
    ],
    otp_code: [
        (code: string) => code.length === 4,
    ],
    birth_date_day: [
        (day: string) => day.trim().length >= 1,
    ],
    birth_date_month: [
        (month: string) => month.trim().length >= 1,
    ],
    birth_date_year: [
        (year: string) => year.trim().length === 4,
    ],
};

export function isFieldValid(field: keyof IFormsValues, value: FormValue) {
    const rules = validationRules[field] || [];

    return !rules.some(rule => !rule(value));
}

type FormValueEntry = [keyof IFormsValues, FormValue];
export function isFormValid(formValues: Partial<IFormsValues>) {
    return !Object
        .entries(formValues)
        .some(([field, value]: FormValueEntry) => !isFieldValid(field, value));
}
