import {
    AvailableLocales,
} from 'src/libs/locale';

const privacyPolicyEnglish =  `<h3>PRIVACY POLICY</h3>
<p>Welcome, and thank you for your interest in Master Delivery LLC (“Company”, “we”, “our” or “us”), our websites including <a href='https://pryamicom.com' target='_blank'>https://pryamicom.com</a> (“Site”),
mobile applications including application located at Android - <a href='https://play.google.com/store/apps/details?id=ru.masterdelivery.courier&hl=en' target='_blank'>https://play.google.com/store/apps/details?id=ru.masterdelivery.courier&hl=en</a>;
iOS - <a href='https://apps.apple.com/us/app/id1542040133' target='_blank'>https://apps.apple.com/us/app/id1542040133</a>, including tablet application (“Application”). We are committed to protecting your personal information and your right to privacy.
This Privacy Policy will explain how Master Delivery LLC uses the personal data we collect from you when you use our Site or Application.</p>
Our Privacy policy explains:
<ul>
    <li>what information do we collect and why we collect it</li>
    <li>how we use, store and protect this information</li>
</ul>
Our Privacy policy applies to:
<ul>
    <li>Couriers - users who use our Application and/or our Site for the purpose of registration as a couriers and in order to use Application during delivery process</li>
    <li>Consumers – persons to whom their order is to be delivered by Couriers</li>
</ul>
<p>If you have questions or complaints regarding our Privacy policy or practices, please contact us at <a href= "mailto:privacy@masterdelivery.ru">privacy@masterdelivery.ru</a>.</p>

<h3>WHAT INFORMATION DO WE COLLECT, WHY WE COLLECT IT AND HOW IT IS USED</h3>
<h4><b>1. Couriers’ information</b></h4>
<p>Since our Application is designed for coordination of delivery process to provide the Application to you and to make sure it functions properly, we use some of Couriers’ personal data.</p>
<b>We collect the following Couriers’ information:</b>
<ul>
    <li>full name</li>
    <li>email address</li>
    <li>phone number</li>
    <li>vehicle type</li>
    <li>city</li>
    <li>usage data (e.g. browsing time)</li>
    <li>IP address</li>
    <li>GPS location</li>
    <li>Couriers’ device information (e.g. his/her Internet connection, browser type, version and operating system, type of device)</li>
</ul>
<b>Why we process the above information</b>
<ul>
    <li>
        <u>Registration and Login</u><br/>
        To limit the access and to improve the security of the Application we ask Couriers to login to the Application with the name, phone number and email address. During the login we ask Couriers’ city and ask to select vehicle type so that we can then select the best routes. The legal ground for the processing of this information is the legitimate interest of the Company.
    </li>
    <li>
        <u>GPS location</u><br/>
        The Application shares Couriers’ GPS location with us during shift so we can coordinate the delivery process. GPS location is only shared during the delivery of an order and will not be
        tracked after the order has been delivered. The legal ground for the processing of this information is the legitimate interest of the Company.
        Courier is able to turn off the location sharing by changing his/her device settings. In such situation Application will not function properly.
    </li>
    <li>
        <u>Analytics</u><br/>
        Personal information that is processed within the Application is also used for analytical purposes and to improve the Application and delivery process. The legal ground for the processing of this information is the legitimate interest of the Company.
    </li>
    <li>
        <u>Sharing with Logistic companies</u><br/>
        Logistic companies (companies which provide delivery services and for that purpose use our software under the license agreement) have access to the Couriers’ data with a view to interaction for and during delivery process.
    </li>
    <li>
        <u>Sharing with others</u><br/>
        We will not sell Couriers’ personal information to third parties, and we will only disclose minimum possible part of it to third parties if necessary for the providence or performance of the Application to Couriers, analytical purposes, or to comply with legal obligations. Categories of third parties that may be used by the Company are:
        <ul>
            <li>Software providers</li>
            <li>Implementation partners</li>
        </ul>
        Whenever we instruct third-party companies to process personal data on our behalf, we will enter into a data processing agreement to guarantee the same level of protection and confidentiality of personal data. And we will then continue to have ultimate responsibility for such processing operations.
    </li>
    <li>
        <u>Use of Consumers</u><br/>
        When delivering orders Couriers also receive data of the Consumers. We would like to point out that the data of the Consumers may only be used for the delivery of the order. The use of this data for other purposes than the delivery of the order is prohibited by law.
    </li>
</ul>
<h4><b>2. Consumers’ information</b></h4>
<p>When delivering orders some Consumers’ personal information gets into the Application. Such information arises when Logistic company transfer to Couriers information about the order to be delivered.</p>
<b>We have access to the following Consumers’ information:</b>
<ul>
  <li>name</li>
  <li>delivery address details</li>
  <li>contact details (phone number, email address)</li>
  <li>order</li>
  <li>payment details (order paid or not paid)</li>
  <li>comments (if applicable)</li>
</ul>
<b>We have access to the following Consumers’ information:</b>
<ul>
    <li>
        <u>Delivery process</u><br/>
        The above personal data used for the delivery of the order. Couriers receive data of the Consumers such as name and delivery address details, order, payment detail, comments just for the delivery of the order. We also disclose phone number to Twilio Inc. (SMS messaging service) and Voximplant Inc. (calling service) to provide to the Consumers information about
        the delivery. We will enter into a data processing agreement to guarantee the same level of protection and confidentiality of personal data. And we will then continue to have ultimate responsibility for such processing operations.
        The legal basis for this processing of personal data is that it is needed for the performance of a contract as defined in the GDPR.
    </li>
    <li>
        <u>Analytics</u><br/>
        Above Personal information that is processed within the Application is also used for analytical purposes and to improve the Application and delivery process. Only several employees providing technical support have access to the Consumers’ data. The legal ground for the processing of this information is the legitimate interest of the Company.
    </li>
</ul>

<h3>HOW IS INFORMATION KEPT SECURE</h3>
The security of the processed personal information is important to us. We have implemented various measures to ensure that the information is adequately protected against unauthorized access, use, disclosure and destruction. You should bear in mind, however, that submission of information over the internet is never entirely secure. Thus, risk can never be eliminated, however, it can definitely be significantly mitigated and reduced.<br/>
Security measures adopted by the Company include (but not limited) the following:
<ul>
    <li>access to the information stored within our servers is restricted to a limited number of our employees</li>
    <li>we use industry best practices to keep any information collected and/or transmitted to the Supported Platforms secure</li>
    <li>we review information collection and processing practices periodically and will review and amend this Privacy Policy accordingly</li>
</ul>

<h3>HOW LONG WE STORE PERSONAL DATA FOR</h3>
The Company will not store personal data for longer than is strictly necessary for the purposes for which such personal data were collected. We will only store your personal data for longer if we are required by law to do so.<br/>
We store personal data of the Couriers 2 months after the Courier has deleted his/her account in the Application. We use this term for administrative purposes and to be able to deal with possible questions and complaints about delivery process and the use of the Application. After that period, Couriers’ personal data will be automatically deleted.<br/>
We store personal data of the Consumers 1 months after the Consumer has gotten his/her order. We use this term for administrative purposes and to be able to deal with possible complaints and mistakes in the Application. After that period, Consumer’ personal data will be automatically deleted.<p/>

<h3>WHAT ARE YOUR DATA PROTECTION RIGHTS?</h3>
Our Company would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
<ul>
    <li>the right to access – the right to request the Company for copies of your personal data;</li>
    <li>the right to rectification –the right to request the Company correct any information you believe is inaccurate or to request the Company to complete the information you believe is incomplete;</li>
    <li>the right to erasure – the right to request the Company erase your personal data, under certain conditions;</li>
    <li>the right to restrict processing – the right to request that the Company restrict the processing of the personal data, under certain conditions;</li>
    <li>the right to object to processing – the right to object to the Company’s processing of personal data, under certain conditions;</li>
    <li>the right to data portability – the right to request that the Company transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
</ul>
If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: <a href= "mailto:privacy@masterdelivery.ru">privacy@masterdelivery.ru</a>

<h3>CHILDREN</h3>
Our Application and Site are neither intended for persons aged under 18, and nor do we intend to collect personal data of persons who are aged under 18. However, we are unable to verify visitors’ age. We therefore advise parents to monitor their children\\'s online activities, so as to prevent their personal data being collected without parental consent. If you feel that we have collected personal data of a minor without consent, please contact us at privacy@masterdelivery.ru. We will then proceed to erase these data.<p/>

<h3>NOTICES AND REVISION TO THE PRIVACY POLICY</h3>
If you have any concern about your privacy, you are kindly requested to forward an email to the Company at privacy@masterdelivery.ru containing a detailed description of your concerns. We will do its best to resolve such issues within a reasonable time.
We may revise this Privacy policy from time to time. The most current version of the policy, found on the Application and the Site, will govern our use of your information collected and processed by the Company.<p/>

<h3>CONSENT</h3>
By using the Application and/or the Site, or submitting personal information to the Company, you consent to the processing of your personal information in the manner set out in this Privacy policy. If you do not consent to the use of your personal information in these ways, you shall refrain from using the Application and the Site.<p/>

<h3>TIPS, QUESTIONS, AND COMPLAINTS</h3>
<p>If you have any other questions or complaints about the processing of your personal data, we will be happy to talk to you. And we would also like to hear from you if you have tips or suggestions on how to improve our Privacy policy.<p/>
<b>CONTACT DETAILS</b><br/>
Master Delivery LLC<br/>
Staropetrovskiy proezd 11, bld.1, floor 4,<br/>
room 28 125130, Moscow, the Russian Federation<br/>
Email: privacy@masterdelivery.ru<br/>`;

export default {
    [AvailableLocales.Russian]: {
        goBack: 'Назад',
        content: `<h3>Правила обработки персональных данных</h3>
        <p>Заполняя настоящую форму регистрации («Стать курьером»), Вы, как субъект персональных данных (далее – «Субъект»), предоставляете согласие на обработку вносимых в форму персональных данных (далее – «Персональные данные») в соответствии с требованиями статьи 9 Федерального закона от 27.07.2006 No 152-ФЗ «О персональных данных» Обществом с ограниченной ответственностью «Мастер Деливери», ОГРН 1207700307077, ИНН 7743345801, адрес: 125130, г. Москва, Старопетровский проезд, д.11, корп.1, эт.4, комн.28 (далее – «Оператор») на указанных ниже условиях. Во избежание сомнений – форма регистрации «Стать курьером» располагается на нескольких экранах (до кнопки «Отправить анкету»).</p>
        <p>Субъект ознакомлен и согласен с тем, что в соответствии с требованиями Федерального закона от 27 июля 2006 г. No152-ФЗ «О персональных данных» Оператором будет осуществляться обработка предоставляемых Субъектом Персональных данных, в том числе: фамилии, имени, отчества; даты рождения; адреса проживания; реквизиты основного документа, удостоверяющего личность, сведений о дате выдачи указанного документа и выдавшем его органе; номерах телефонов, адрес электронной почты; фото- и видеоизображения Субъекта; адресах электронной почты (е-mail), сведений о геолокации, информация об аппаратном и программном обеспечении (тип и модель используемого устройства, идентификатор устройства, информация об операторе связи, совершаемые действия и т.д.), а также иных данных, предоставляемых Субъектом.</p>
        <p>Обработка Персональных данных Субъекта происходит как с использованием средств автоматизации, так и в ручном режиме без использования таких средств.</p>
        <p>Обработка Персональных данных Субъекта Оператором включает в себя сбор, запись, систематизацию, накопление, хранение, извлечение, уточнение, обновление, изменение, использование, обезличивание, предоставление, блокирование, уничтожение.</p>
        <p>Настоящее согласие не устанавливает предельных сроков обработки Персональных данных Субъекта. При этом обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.</p>
        <p>Персональные данные предоставляются и обрабатываются в целях регистрации Субъекта в настоящем мобильном приложении (далее – «Приложение»), рассмотрения вопроса о заключении договора с Субъектом, подготовки к заключению договора с Субъектом, исполнения договора, если с Субъектом будет заключен договор, а также для предоставления Субъекту доступа к функционалу Приложения и с целью предоставления Субъекту информации, связанной с использованием Приложения. Указанная информация может быть предоставлена Субъекту в Приложении, по предоставленному телефону, а также с применением неголосовых коммуникаций (путем рассылки по сети подвижной радиотелефонной связи коротких текстовых sms-сообщений, рассылки ussd-сообщений и др.), посредством направления Субъекту сведений по информационно-телекоммуникационной сети «Интернет» на предоставленные Субъектом номер телефона и (или) адрес электронной почты.</p>
        <p>Субъект предоставляет согласие на передачу Персональных данных лицам, с которыми у Оператора заключены договоры на оказание услуг по доставке, агентские договоры, договоры на рассылку сервисных сообщений и т.д., в объеме, необходимом для исполнения таких договоров и договора, заключенного с Субъектом.</p>
        <p>Оператор в рамках данного согласия не получает и не обрабатывает персональные данные Субъекта о расовой, национальной принадлежности, политических взглядах, религиозных или философских убеждениях, состоянии здоровья, интимной жизни.</p>
        <p>Субъект вправе отозвать данное согласие на обработку своих Персональных данных. Отзыв настоящего согласия может быть осуществлен в любое время путем направления письменного уведомления об отзыве в адрес Оператора (125130, г. Москва, Старопетровский проезд, д.11, корп.1, эт.4, комн.28). После получения отзыва настоящего согласия Оператор обязан прекратить обработку Персональных данных или обеспечить прекращение такой обработки (если обработка Персональных данных осуществляется другим лицом, действующим по поручению Оператора) и в случае, если сохранение Персональных данных более не требуется для целей обработки Персональных данных, уничтожить Персональные данные Субъекта или обеспечить их уничтожение (если обработка Персональных данных осуществляется другим лицом, действующим по поручению Оператора).</p>
        <p>Субъект согласен с тем, что в случае отзыва настоящего согласия, Оператор прекращает доступ Субъекта к функционалу Приложения.</p>`,
    },
    [AvailableLocales.English]: {
        goBack: 'Back',
        content: privacyPolicyEnglish,
    },
    [AvailableLocales.French]: {
        goBack: 'Retour',
        content: privacyPolicyEnglish,
    },
    [AvailableLocales.German]: {
        goBack: 'Zurück',
        content: privacyPolicyEnglish,
    },
    [AvailableLocales.Spanish]: {
        goBack: 'Atrás',
        content: privacyPolicyEnglish,
    },
    [AvailableLocales.Italian]: {
        goBack: 'Indietro',
        content: privacyPolicyEnglish,
    },
    [AvailableLocales.Turkish]: {
        goBack: 'Geri',
        content: privacyPolicyEnglish,
    },
};
