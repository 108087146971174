import './reset.scss';
import 'antd/dist/antd.css';
import './antd.reset.scss';
import './MainLayout.scss';

import React from 'react';
import * as b_ from 'b_';

import {
    YMInitializer,
} from 'react-yandex-metrika';

import {
    LOCALE,
    MAIN_SITE_URL,
    YA_METRIKA,
} from 'src/config';

interface IProps {
    children?: any;
}

const b = b_.with('main-layout');

export default function MainLayout({
    children,
}: IProps) {
    return (
        <div className={b()}>
            <div className={b('wrapper')}>
                <a
                    href={MAIN_SITE_URL}
                    target={'_blank'}
                    className={b('logo', {
                        lang: LOCALE,
                    })}
                />
                <div className={b('inner')}>
                    {children}
                </div>
            </div>
            <YMInitializer
                {...YA_METRIKA}
            />
        </div>
    );
}
