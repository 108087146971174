import './PrivacyPolicy.scss';
import locales from './PrivacyPolicy.i18n';

import {
    Button,
} from 'antd'
import b_ from 'b_';
import React from 'react'

import {
    isWebView,
} from 'src/libs/detect'
import i18n from 'src/libs/i18n';

const b = b_.with('privacy-policy');
const __ = i18n(locales);

interface IPrivacyPolicyProps {
    onReturnBack?: () => void;
}

export default function PrivacyPolicy({
    onReturnBack = () => {},
}: IPrivacyPolicyProps) {
    const showBackButton = isWebView();

    return (
        <div className={b()}>
            <div
                className={b('rules')}
                dangerouslySetInnerHTML={{__html: __('content')}}
            />
            {showBackButton && (
                <Button
                    danger
                    shape='round'
                    size='large'
                    type='primary'
                    onClick={onReturnBack}
                >
                    {__('goBack')}
                </Button>
            )}
        </div>
    )
}
